<template>
  <div class="vacancy-view">
    <control-panel>
      <template v-slot:side-menu>
        <div class="side-menu"></div>
      </template>

      <template v-slot:content>
        <v-form v-model="vacancy.data.formIsValid" ref="vacancyForm">
          <div class="content">
            <div class="vacancy-header">
              <div class="vacancy-header__title">
                {{vacancy.data.title}}
              </div>
            </div>
            <div class="vacancy-content">
              <div class="vacancy-content__section">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_role')}}
                  </div>
                  <v-combobox
                    class="combobox__item padding-16"
                    v-model="vacancy.data.rolesSelected"
                    :items="vacancy.data.roles"
                    :label="$localize.get('vacancy_field_role')"
                    :rules="roleSelectedIsValid"
                    validate-on-blur
                    single-line
                    outlined
                  >
                    <template v-slot:append>
                      <img src="../assets/chevron-down-blue.svg">
                    </template>
                  </v-combobox>
                </div>
              </div>
              
              <div class="vacancy-content__section vacancy-content__section--flex-2">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_specialist_grade')}}
                  </div>
                  <v-combobox
                    class="combobox__item padding-16"
                    v-model="vacancy.data.specialistGradesSelected"
                    :items="specialistGrades"
                    :label="$localize.get('vacancy_field_select')"
                    :rules="specialistGradesSelectedIsValid"
                    validate-on-blur
                    multiple
                    single-line
                    outlined
                  >
                    <template v-slot:append>
                      <img src="../assets/chevron-down-blue.svg">
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected, index }">
                      <span v-if="index < 3">
                        <v-chip
                          v-bind="attrs"
                          color="var(--blue-main)"
                          :input-value="selected"
                          dark
                          label
                        >
                          <span class="pr-2">
                            {{item}}
                          </span>
                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </span>
                      <span
                        v-if="index >= 3"
                        class="grey--text caption ml-1"
                      >
                        (+{{vacancy.data.specialistGradesSelected.length - 3}} {{$localize.get('other')}})
                      </span>
                    </template>
                  </v-combobox>
                </div>

                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_specialist_amount')}}
                  </div>
                  <v-text-field
                    class="combobox__item padding-16"
                    v-model="vacancy.data.specialistAmount"
                    :label="$localize.get('vacancy_field_specialist_amount')"
                    :rules="specialistAmountIsValid"
                    validate-on-blur
                    single-line
                    outlined
                  ></v-text-field>
                </div>
              </div>

              <div class="vacancy-content__section vacancy-content__section--flex-2">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_english_level')}}
                  </div>
                  <v-combobox
                    class="combobox__item padding-16"
                    v-model="vacancy.data.englishLevelSelected"
                    :items="englishLevels"
                    :label="$localize.get('vacancy_field_select')"
                    :rules="englishLevelSelectedIsValid"
                    validate-on-blur
                    single-line
                    outlined
                  >
                    <template v-slot:append>
                      <img src="../assets/chevron-down-blue.svg">
                    </template>
                    <template v-slot:selection="{ item: selectionItem, index }">
                      <span v-if="index === 0">{{selectionItem}}</span>
                      <span
                        v-if="index === 1"
                        class="grey--text caption ml-1"
                      >
                        (+{{vacancy.data.englishLevelSelected.length - 1}} {{$localize.get('other')}})
                      </span>
                    </template>
                  </v-combobox>
                </div>

                <div>
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_job_type')}}
                  </div>
                  <v-btn-toggle
                    v-model="vacancy.data.jobType"
                    color="var(--blue-main)"
                    dense
                    group
                  >
                    <div 
                      class="chip" 
                      @click="vacancy.data.jobType = 'Full-time'"
                      :active="vacancy.data.jobType === 'Full-time'"
                    >
                      <div class="chip__item">
                        Full-time
                      </div>
                    </div>
                    <div 
                      class="chip" 
                      @click="vacancy.data.jobType = 'Part-time'"
                      :active="vacancy.data.jobType === 'Part-time'"
                    >
                      <div class="chip__item">
                        Part-time
                      </div>
                    </div>
                  </v-btn-toggle>
                </div>
              </div>

              <div class="vacancy-content__section">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_skills')}}
                  </div>
                  <v-text-field
                    class="combobox__item padding-16"
                    v-model="vacancy.data.skills"
                    :label="$localize.get('vacancy_field_skills')"
                    single-line
                    outlined
                  ></v-text-field>
                </div>
              </div>

              <div class="vacancy-content__section">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_specialist_min_requirements')}}
                  </div>
                  <v-text-field
                    class="combobox__item padding-16"
                    v-model="vacancy.data.minimumRequirements"
                    :label="$localize.get('vacancy_field_specialist_min_requirements')"
                    single-line
                    outlined
                  ></v-text-field>
                </div>
              </div>

              <div class="vacancy-content__section">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_project_description')}}
                  </div>
                  <v-text-field
                    class="combobox__item padding-16"
                    v-model="vacancy.data.aboutProject"
                    :label="$localize.get('vacancy_field_project_description')"
                    single-line
                    outlined
                  ></v-text-field>
                </div>
              </div>
              
              <div class="vacancy-content__section">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_location')}}
                  </div>
                  <v-combobox
                    class="combobox__item padding-16"
                    v-model="vacancy.data.countrySelected"
                    :items="countries"
                    :label="$localize.get('vacancy_field_select')"
                    item-text="name"
                    item-value="name"
                    return-object
                    multiple
                    single-line
                    outlined
                  >
                    <template v-slot:append>
                      <img src="../assets/chevron-down-blue.svg">
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected, index }">
                      <span v-if="index < 5">
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          color="var(--blue-main)"
                          :input-value="selected"
                          dark
                          label
                        >
                          <span class="pr-2">
                            {{item.name}}
                          </span>
                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </span>
                      <span
                        v-if="index >= 5"
                        class="grey--text caption ml-1"
                      >
                        (+{{vacancy.data.countrySelected.length - 5}} {{$localize.get('other')}})
                      </span>
                    </template>
                  </v-combobox>
                </div>
              </div>

              <div class="vacancy-content__section vacancy-content__section--flex-2">
                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_hourly_rate')}}
                  </div>
                  <v-text-field
                    class="combobox__item padding-16"
                    v-model="vacancy.data.hourlyRate"
                    :label="$localize.get('vacancy_field_hourly_rate')"
                    single-line
                    outlined
                  ></v-text-field>
                </div>

                <div class="combobox">
                  <div class="combobox__title">
                    {{$localize.get('vacancy_label_customer')}}
                  </div>
                  <v-combobox
                    class="combobox__item padding-16"
                    v-model="vacancy.data.customerSelected"
                    :items="customers"
                    :label="$localize.get('vacancy_field_select')"
                    :rules="customerSelectedIsValid"
                    validate-on-blur
                    item-text="name"
                    single-line
                    outlined
                  >
                    <template v-slot:append>
                      <img src="../assets/chevron-down-blue.svg">
                    </template>
                  </v-combobox>
                </div>
              </div>

              <v-divider class="mt-12 mb-10"></v-divider>

              <div class="vacancy-content__section vacancy-content__section--flex-space">
                <h-button
                  @click.native="$store.dispatch('CANCEL_EDIT_VACANCY')"
                  large outlined green
                >{{$localize.get('cancel')}}
                </h-button>

                <v-tooltip 
                  :disabled="vacancy.data.formIsValid"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <h-button
                        @click.native="
                          vacancy.type === 'new'
                            ? addVacancy()
                            : editVacancy()
                        "
                        large filled green
                      >
                        {{vacancy.type === 'new'
                          ? $localize.get('publish')
                          : $localize.get('save')
                        }}
                      </h-button>
                    </div>
                  </template>
                  <span>{{$localize.get('some_form_fields_are_invalid')}}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-form>
      </template>
    </control-panel>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Vacancy',
  computed: {
    ...mapFields({
      vacancy: 'vacancy',
      countries: 'countries',
      customers: 'customers',
      specialistGrades: 'specialistGrades',
      englishLevels: 'englishLevels',
    }),
    roleSelectedIsValid () {
      return [
        value => value.length > 0 || this.$localize.get('field_is_required')
      ]
    },
    specialistGradesSelectedIsValid () {
      return [
        value => value.length > 0 || this.$localize.get('field_is_required')
      ]
    },
    specialistAmountIsValid () {
      return [
        value => value.length > 0 || this.$localize.get('field_is_required')
      ]
    },
    englishLevelSelectedIsValid () {
      return [
        value => value.length > 0 || this.$localize.get('field_is_required')
      ]
    },
    customerSelectedIsValid () {
      return [
        value => value?.name ? true : false || this.$localize.get('field_is_required')
      ]
    },
  },
  methods: {
    async validateVacancyEditor () {
      return new Promise((resolve, reject) => {
        this.$refs.vacancyForm.validate()
        this.$nextTick(() => {
          resolve(this.vacancy.data.formIsValid)
        })
      })
    },
    addVacancy () {
      this.validateVacancyEditor()
        .then(() => {
          this.$store.dispatch('ADD_VACANCY')
        })
    },
    editVacancy () {
      this.validateVacancyEditor()
        .then(() => {
          this.$store.dispatch('EDIT_VACANCY')
        })
    },
  },
}
</script>

<style lang="scss">
.vacancy-view {
  height: 100%;
  .vacancy-header {
    margin-bottom: 32px;
  }
  .vacancy-header__title {
    color: var(--blue-main);
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-transform: capitalize;
  }
  .vacancy-content__section {
    margin-bottom: 4px;
  }
  .vacancy-content__section--flex-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 38px;
  }
  .vacancy-content__section--flex-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .combobox__title {
    margin-bottom: 12px;
    color: var(--grey-3);
    font-size: 18px;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: normal;
  }
  .combobox__item {
    label {
      color: var(--grey-2);
      font-family: 'Proxima Nova Rg';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }
    .v-select__selection {
      flex-wrap: nowrap;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }
    .v-select__selections {
      flex-wrap: nowrap;
      max-width: 90%;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .chip-switch {
    display: flex;
  }
  .chip {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      margin-bottom: 20px;
      margin-right: 8px;
      padding: 0px 16px;
      color: var(--grey-2);
      background-color: #fff;
      border-radius: 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      cursor: pointer;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      &:nth-child(n + 3) {
        @media (max-width: $md) {
          display: none;
        }
      }
    }
  }
  .chip[active] {
    .chip__item {
      color: #fff;
      background-color: var(--blue-main);
    }
  }
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    border: 1px solid var(--grey-2);
  }
  .v-form {
    display: contents;
  }
}
</style>